import React from "react";
import ContainerColumn from "../ContainerColumn";

const containerRow = ({ children }) => {
  if (children && !Array.isArray(children)) {
    children = [children];
  }

  children = children.filter((comp) => {
    return comp.type === ContainerColumn;
  });

  return <div className="grid-x grid-margin-x grid-margin-y">{children}</div>;
};

export default containerRow;
